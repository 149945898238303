import { GithubContext } from '../context/context';
import './LatestOppurtunity.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';


const SingleOpportunity = (opportunity) => {
    const { isDesktop } = React.useContext(GithubContext);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        if (isDesktop) {
            setShowInfo(true)
        }
    }, [isDesktop])

    const goToOpportunity = (OppId) => {

        localStorage.setItem('OppId', `${OppId}`);
    }
    return (
        <div role="row" className={`opportunity-table-row ${showInfo ? 'active-show' : ''}`}>
            <>

                <div role="cell" className='opportunity-table-row-cell full-width'>
                    {!isDesktop &&
                        <div className='mobile-first-head'>
                            <p className='mobile-table-head'>Opportunity ID: {opportunity.opportunityId}</p>
                            <Link to="/oppurtunity-detail" onClick={() => goToOpportunity(opportunity.opportunityId)} className='view-link'>View</Link>
                        </div>
                    }
                    {isDesktop &&
                        <Link to="/oppurtunity-detail" onClick={() => goToOpportunity(opportunity.opportunityId)}>{opportunity.opportunityId}</Link>
                    }

                </div>

                <div role="cell" className='opportunity-table-row-cell full-width'>
                    {!isDesktop &&
                        <p className='mobile-table-head'>Account Name</p>
                    }
                    {opportunity.client_Name}
                </div>
                <div role="cell" className='opportunity-table-row-cell full-width mob-border'>
                    {!isDesktop &&
                        <p className='mobile-table-head'>Opportunity Name</p>
                    }
                    {opportunity.engagement_Name}
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>
                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>Jupiter</p>
                        }
                        {opportunity.jupiterStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.jupiterStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.jupiterStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.jupiterStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.jupiterStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.jupiterStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.jupiterStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>
                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>DRMS</p>
                        }
                        {opportunity.drmsStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.drmsStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.drmsStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.drmsStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.drmsStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.drmsStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.drmsStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>
                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>IRMS</p>
                        }
                        {opportunity.irmsStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.irmsStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.irmsStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.irmsStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.irmsStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.irmsStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.irmsStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>

                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>P2W</p>
                        }
                        {opportunity.pmsStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.pmsStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.pmsStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.pmsStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.pmsStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.pmsStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.pmsStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>
                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>ECM</p>
                        }
                        {opportunity.ecmStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.ecmStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.ecmStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.ecmStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.ecmStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.ecmStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.ecmStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
                <div role="cell" className='opportunity-table-row-cell inline-flex'>
                    <div className="status-column">
                        {!isDesktop &&
                            <p className='mobile-table-head'>SAP</p>
                        }
                        {opportunity.sapStatus == 1 ? <div className='completed-text status'>C</div>
                            : opportunity.sapStatus == 2 ? <div className='progress-text status'>P</div>
                                : opportunity.sapStatus == 3 ? <div className='not-started-text status'>NS</div>
                                    : opportunity.sapStatus == 4 ? <div className='not-required-text status'>NR</div>
                                        : opportunity.sapStatus == 5 ? <div className='rejected-text status'>R</div>
                                            : opportunity.sapStatus == 6 ? <div className='closed-lost-text status'>CL</div>
                                                : opportunity.sapStatus == 0 ? <div className='not-started-text status'>NS</div>
                                                    : <div className='progress-text status'>P</div>
                        }
                    </div>
                </div>
            </>

        </div>
    )
}

const OpportunitiesDeatils = ({ opportunities }) => {

    const { isDesktop } = React.useContext(GithubContext);
    const tableHeadCell = ['opportunity-table-header-cell', isDesktop ? '' : "mobile-hide"].join(' ');
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = opportunities.slice(firstIndex, lastIndex);
    const npage = Math.ceil(opportunities.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const SearcOpp = localStorage.getItem("SearcOpp")

    
    
    return (
        <>
            
            <div className='opportunity-table-block'>
                <div className='opportunity-table' role="table" aria-label="Opportunity Lists Table">
                    <div role="row"  className='opportunity-table-header'>
                        <div role="columnheader" className={tableHeadCell}>Opportunity ID</div>
                        <div role="columnheader" className={tableHeadCell}>Account Name</div>
                        <div role="columnheader" className={tableHeadCell}>Opportunity Name</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>Jupiter</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>DRMS</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>IRMS</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>P2W</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>ECM</div>
                        <div role="columnheader" className={`opportunity-table-header-cell text-center ${isDesktop ? '' : "mobile-hide"}`}>SAP</div>
                    </div>

                    <div className='opportunity-table-body' role="status" aria-live="assertive" aria-atomic="true">
                        {records.length > 0 ?
                            records.map((opportunity) => {
                                return (
                                    <SingleOpportunity key={opportunity.opportunityId} {...opportunity}></SingleOpportunity>
                                )
                            }) : ''
                        }

                    </div>
                </div>
            </div>
            {/* Pagination start */}
            {records.length > 0 ?
            <ul className="pagination" role="navigation" aria-label="Pagination">

                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}><button role='button' disabled={currentPage === 1} aria-disabled={currentPage === 1 ? 'true' : 'false'} tabIndex="0" aria-label="Previous page" className="page-link" onClick={prepage}>Previous</button></li>
                {
                    numbers.map((n, i) => (
                        <li className='page-item' key={i}>
                            <button role='button' className={`page-link ${currentPage === n ? 'active' : ''}`} tabIndex="0" aria-label={`Go to page ${n}`} aria-current={currentPage === n ? 'page' : undefined} onClick={() => changeCPage(n)}>{n}</button>
                        </li>
                    ))
                }
                <li className={`page-item ${currentPage === npage ? 'disabled' : ''}`}><button role='button' disabled={currentPage === npage} aria-disabled={currentPage === npage ? 'true' : 'false'} tabIndex="0" aria-label="Next page" className="page-link" onClick={nextpage}>Next</button></li>
            </ul> : ''
            }
            {/* Pagination end */}




        </>
    )
    function prepage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id ) {
        setCurrentPage(id)
    }
    function nextpage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}


const LatestOppurtunity = () => {
    const [isLoading, setIsLoading] = useState(true);
    const loaderRef = useRef(null); 
    const resultsRef = useRef(null);

    //const UserId = localStorage.getItem("userId")

    const [Opportunities, setOpportunities] = useState([]);
    const { isDesktop } = React.useContext(GithubContext);
    const [showInfo, setShowInfo] = useState(false);
    const tableHeadCell = ['opportunity-table-header-cell', isDesktop ? '' : "mobile-hide"].join(' ');
    const SearcOpp = localStorage.getItem("SearcOpp")


    //const goToOpportunity = (e) => {

    //    localStorage.removeItem("SearcOpp");
    //    fetchOpportunities();
    //    document.getElementById("textSearch").value = "";
    //}

    useEffect(() => {
        if (!isLoading && localStorage.getItem("SearcOpp")?.length > 0 && resultsRef.current) {
            resultsRef.current.focus();
        }
    }, [Opportunities, isLoading]);
   

    const fetchOpportunities = () => {

        if (localStorage.getItem("SearcOpp") != undefined) {
            if (localStorage.getItem("SearcOpp").length > 0) {
                var opp = localStorage.getItem("SearcOpp");
                setIsLoading(true);
                fetch('jupiterdata/GetData/' + opp + '/' + localStorage.getItem("userId")).then(response => {
                    return response.json();
                }).then(data => {
                    if (data.length > 0) {
                        setOpportunities(data);
                        //localStorage.removeItem("SearcOpp");
                        if (!isLoading && localStorage.getItem("SearcOpp")?.length > 0 && resultsRef.current) {
                            resultsRef.current.focus();
                        }
                        setIsLoading(false);                        
                    }
                    else {
                        setOpportunities(data);
                        //localStorage.removeItem("SearcOpp");
                        if (!isLoading && localStorage.getItem("SearcOpp")?.length > 0 && resultsRef.current) {
                            resultsRef.current.focus();
                        }
                        setIsLoading(false);
                    }
                }).catch(function (err) {
                    console.log('SOMETHING WENT WRONG'); 
                    setIsLoading(false);
                });
                //.catch((error) => { console.log(error); setIsLoading(false); })

            }
            else {

                console.log("Search Part Else")
                setIsLoading(true);
                fetch('jupiterdata/GetData/All/' + localStorage.getItem("userId")).then(response => {
                    return response.json();
                }).then(data => {
                    setOpportunities(data);
                    setIsLoading(false);
                }).catch(function (err) {
                    console.log('SOMETHING WENT WRONG');
                    setIsLoading(false);
                });
            }
        }
        else {
            setIsLoading(true);
            console.log("Search Main Part")
            fetch('jupiterdata/GetData/All/' + localStorage.getItem("userId")).then(response => {
                return response.json();
            }).then(data => {
                setOpportunities(data);
                console.log(data);
                setIsLoading(false);
            }).catch(function (err) {
                console.log('SOMETHING WENT WRONG');
                setIsLoading(false);
            });
        }
    }


    useEffect(() => {
        fetchOpportunities()
    }, [])
    useEffect(() => {
        if (isDesktop) {
            setShowInfo(true)
        }
    }, [isDesktop])

    useEffect(() => {
        if (isLoading && loaderRef.current) {
          const loaderElement = loaderRef.current;
    
          const handleTabKey = (e) => {
            if (e.key === 'Tab') {
              e.preventDefault();
              if (document.activeElement !== loaderElement) {
                loaderElement.focus();
              }
            }
          };
          loaderElement.focus();
          
          document.addEventListener('keydown', handleTabKey);
          
          return () => {
            document.removeEventListener('keydown', handleTabKey);
          };
        }
    }, [isLoading]);

    return (
        <>
            <div className="oppurtunity-left">                
                <div ref={resultsRef} role="status" aria-live="assertive" aria-atomic="true">
                    {Opportunities.length > 0 && (
                       <>
                            <h2 className='mb-2 opp-length'>Total Opportunities: {Opportunities.length}</h2>
                            <OpportunitiesDeatils opportunities={Opportunities} />
                             {/* Status List Start */}
                            <ul className="status-list">
                                <li className="status-item ">
                                    <div className="completed-icon-status status-item-icon">C</div>
                                    Completed
                                </li>
                                <li className="status-item ">
                                    <div className="progress-icon-status status-item-icon">P</div>
                                    In Progress
                                </li>
                                <li className="status-item">
                                    <div className="not-started-icon-status status-item-icon">NS</div>
                                    Not Started
                                </li>
                                <li className="status-item">
                                    <div className="not-required-icon-status status-item-icon">NR</div>
                                    Not Required
                                </li>
                                <li className="status-item">
                                    <div className="rejected-icon-status status-item-icon">R</div>
                                    Rejected
                                </li>
                                <li className="status-item">
                                    <div className="closed-lost-icon-status status-item-icon">CL</div>
                                    Closed Lost
                                </li>
                            </ul>
                            {/* Status List End */}
                       </>
                    )}
                </div>
                <div  ref={resultsRef} role="status" aria-live="assertive" aria-atomic="true" tabIndex="0">
                    {Opportunities.length == 0 && !isLoading && <h2 className='mb-2 opp-length'>No Records Found</h2>}
                </div>
            </div> 
            {isLoading && <div className="preloader" role="status" aria-live="assertive"  tabIndex="0" ref={loaderRef} aria-label="Loading Opportunity List, please wait...">
                <div className="loader">
                    <div></div><div></div><div></div>
                </div>              
            </div>}</>)
}

export default LatestOppurtunity;