import React, { useEffect } from 'react';
import './Guideline.css';
import { Link } from 'react-router-dom';

const Guildeline = () => {
    const title = 'O2E Digital Journey - Guideline';
    useEffect(() => {
        document.title = title;
      }, [title]);
  return (
    <section className='guideline section-block'>
        <div className='guideline-header'>
            <Link to="/oppurtunity-list">
                <img src="./image/icons/back.svg" alt='Back to Page' />
            </Link>
            <h1>Guideline</h1>
        </div>
        <div className='guideline-info'>
            <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.
            </p>
            <ul>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
            </ul>
        </div>
    </section>
  )
}

export default Guildeline
