import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(function (err) {
            console.log('SOMETHING WENT WRONG');
        });
}

export async function callMsGraphPhoto(accessToken) {
    //const [userImageUrl, setUserImageUrl] = useState(null)
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "image/jpg");

    const options = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob"
    };

    return fetch(graphConfig.graphMePhotoEndpoint, options)
        .then(res => {
            if (res != null && res.ok) {
                res.blob().then((data) => {
                    if (data !== null) {
                        window.URL = window.URL || window.webkitURL;
                        console.log(window.URL.createObjectURL(data));
                        //setUserImageUrl(window.URL.createObjectURL(data));
                        window.URL.createObjectURL(data);
                    }
                });
            } else {
                //setUserImageUrl('');
                console.log("NO IMAGE FOUND")
            }
        })
        .catch(function (err) {
            console.log('SOMETHING WENT WRONG');
        });
}