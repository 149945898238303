import './OppurtunityDetailSearch.css';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
export default class OppurtunityDetailSearch extends Component {
    constructor(props) {
        super(props);
        this.state = { jupiterid: "" };
    }
    componentDidMount() {
        
        this.setState({ jupiterid: localStorage.getItem("OppId") });
    }

    static renderOpportunityTable() {

        return (

                   <section className="inner-search">
                <div className="inner-search-left">
                    <img src="./image/icons/back.svg" alt="" />
                    <p>Search Result</p>
                </div>
                <div className="inner-search-right">
                    <form className="search-card">
                        <div className="search-card-icon">
                            <img src="./image/icons/grey-search.svg" alt="Search" />
                        </div>
                        <div className="search-card-input">
                            <input className="form-control" type="text" placeholder="067444765123096" />
                        </div>
                        <div className="search-card-button">
                            <button className="btn btn-lg btn-success" type="submit">Search</button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : OppurtunityDetailSearch.renderOpportunityTable();

        return (
            <div>
                               {contents}
            </div>
        );
    }
    
}
//export default OppurtunityDetailSearch;
