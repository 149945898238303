import './Search.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
const Search = () => {
    const [ErrorMessage, setErrorMessage] = useState("");
    const [query, setQuery] = useState('');

    const goToOpportunityData = (e) => {
        if (document.getElementById(e.currentTarget.id).parentElement.previousSibling.children[0].value == "") {
            e.preventDefault()
            setErrorMessage("Please Enter AccoutName / Opportunity Leader / Opportunity Id to search");
        }
        else {
            var searchText = document.getElementById(e.currentTarget.id).parentElement.previousSibling.children[0].value;
            if (searchText.length < 3) {
                e.preventDefault()
                setErrorMessage("Please Enter 3 character atleast to search");
            }
            else {
                localStorage.setItem("SearcOpp", document.getElementById(e.currentTarget.id).parentElement.previousSibling.children[0].value)
            }
        }
    }

    function ClosePopup() {
        setErrorMessage("");
    }

    function ClearSearch() {
        localStorage.removeItem("SearcOpp");
    }

    return (


        <>
            {/*   <div className='oppurtunity-table-header d-sm-block d-md-none'>
            <Link to='' className='guideline-btn'>
                <i className="demo-icon icon-document_1_special">&#x57;</i> Guidelines
            </Link>
        </div>*/}
            <section className="search-main">
                <div className="search-top">
                <div  role="region" aria-live="assertive" aria-atomic="true"  tabIndex="0">
                    {ErrorMessage && <div className='error-section text-center error-msge'>
                        <div className="alert alert-danger">
                            <span><strong>Alert!</strong> {ErrorMessage}.</span>
                            <button type='button' aria-label='Close' className='alert-close' onClick={ClosePopup}><i class="demo-icon icon-cross_special">&#x50;</i></button>
                        </div>
                    </div>}
                </div>
                    <div className="search-top-info">
                        <h1>Track O2E Journey</h1>
                        <form className="search-card">
                            {/* <div className="search-card-icon">
                            <img src="./image/icons/search-icon.svg" alt="Search" />
                        </div> */}
                            <div className="search-card-input">

                                <input aria-label="Search by Account Name / Opportunity Leader / Opportunity Id" className="form-control" id="textSearch" type="text" defaultValue={localStorage.getItem("SearcOpp")} placeholder="Search by Account Name / Opportunity Leader / Opportunity Id" />
                            </div>
                            <div className="search-card-button">
                                <button className="btn btn-lg btn-success" id="btnClick" onChange={(e) => goToOpportunityData(e)} onClick={(e) => goToOpportunityData(e)} type="submit">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
                {localStorage.getItem("SearcOpp") &&
                    <div className='oppurtunity-table-header guilde-line app-pad'>
                        <form>

                            <button onClick={ClearSearch} className='clear-search' type="submit">
                                Clear Search <i aria-hidden="true" className="demo-icon icon-cross_special">&#x50;</i>
                            </button></form>
                        {/*<Link to='' className='guideline-btn'>*/}
                        {/*    Guidelines <i className="demo-icon icon-document_1_special">&#x57;</i>*/}
                        {/*</Link>*/}
                    </div>
                }
                {/*{ErrorMessage && <div className='oppurtunity-list-block m-4 text-center error-msge'>*/}

                {/*    <div class="alert alert-danger">*/}

                {/*        <strong><label id="lblError">{ErrorMessage}</label></strong> */}

                {/*    </div>*/}

                {/*</div>}*/}
                




                <div className="Error">
                </div>

                {/* <div className="search-oppurtunity">
                <img src="./image/icons/grey-search.svg" alt="" />       
                <h2>Search Opportunity</h2>
                <p>Search for an opportunity to track statuses across all applications.</p>
            </div> */}

            </section>


        </>
    )
}

export default Search;