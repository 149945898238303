import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import React from "react";


function RootLayout(props) {
    console.log(props)
    return (
        <>
            <Header userName={props.userName} userPic={props.userPic} userId={props.userId} />
            <main>
                <Outlet />
            </main>
            {window.location.pathname == "/" ? (
                <Footer />
            ) : null}
        </>
    );
}

export default RootLayout;
