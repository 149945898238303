import { Link } from 'react-router-dom';
import './PageContent.css';

function PageContent({ title, children }) {
    return (
        <div className='error-block'>
            <h1>{title}</h1>
            {children}
            <Link to='/' className='btn btn-default'><i className="demo-icon icon-arrow_left_special">&#x2a;</i> Back to Home</Link>
        </div>
    );
}

export default PageContent;