import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import RootLayout from './pages/Root';
import OppurtunityDetail from './pages/OppurtunityDetail';
import ErrorPage from './pages/Error';
import PreHome from './pages/PreHome';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, graphConfig } from './authConfig';

import React, { useEffect, useState } from "react";
import { LoginRedirect } from "./loginRedirect";
import Guildeline from './pages/Guildeline';
import { callMsGraph } from "./graph";

function App() {

    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    const { instance } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [userName, setuserName] = useState(null);
    const [userPic, setuserPic] = useState(null);
    const [userId, setuserId] = useState(null);

    useEffect(() => {

        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest).then((res) => {
                instance.handleRedirectPromise().catch(function (err) {
                    console.log('SOMETHING WENT WRONG');
                });
            });
        }

    });

    useEffect(() => {

        if (graphData == null) {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0]
                }).then((response) => {

                    //localStorage.getItem(response) == null
                    //console.log("1", response);
                    callMsGraph(response.accessToken).then(
                        (response1) => {
                            //localStorage.setItem("userName", response1.givenName + ' ' + response1.surname);
                            localStorage.setItem("userId", response.account.username);
                            setuserName(response1.givenName + ' ' + response1.surname)
                            //console.log(response.accessToken);
                            //console.log(response1)
                            setGraphData(response1);
                            setuserId(response.account.username);
                            //setGraphAPIAccessToken(JSON.stringify(response.accessToken))
                            //console.log("UserID 45", localStorage.getItem("userId"))
                            callMsGraphPhoto(response.accessToken)
                        }).catch(function (err) {
                            console.log('SOMETHING WENT WRONG');
                        });
                }).catch(function (err) {
                    console.log('LAST CATCH')
                });
            }

        }
    }, []);


    function callMsGraphPhoto(accessToken) {

        //const [userImageUrl, setUserImageUrl] = useState(null)
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "image/jpg");

        const options = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },

        };

        fetch(graphConfig.graphMePhotoEndpoint, options)
            .then(res => {
                if (res != null && res.ok) {

                    res.blob().then((data) => {

                        const reader = new FileReader()
                        reader.readAsDataURL(data)
                        reader.onload = () => {
                            const base64data = reader.result;
                            localStorage.setItem("userPic", base64data);
                            if (base64data === "") {
                                setuserPic("../image/user.jpg")
                            }
                            setuserPic(base64data);
                            //if (localStorage.getItem("userPic") != null) {
                            //    localStorage.setItem("userPic", localStorage.getItem("userPic"));
                            //}
                            //else {
                            //    localStorage.setItem("userPic", '');
                            //}

                        }
                        //if (data !== null) {
                        //    window.URL = window.URL || window.webkitURL;
                        //    console.log(window.URL.createObjectURL(data));
                        //    setUserImageUrl(window.URL.createObjectURL(data));
                        //    localStorage.setItem("userPic", window.URL.createObjectURL(data));
                        //}
                    });
                } else {
                    //if (localStorage.getItem("userPic") != null) {
                    //    localStorage.setItem("userPic", localStorage.getItem("userPic"));
                    //}
                    //else {
                    //    localStorage.setItem("userPic", '../image/user.jpg');
                    //}
                    setuserPic("../image/user.jpg");
                }
            })
            .catch(function (err) {
                console.log('SOMETHING WENT WRONG');
            });
    }


    const router = createBrowserRouter([
        {

            path: '/',
            element: <RootLayout userName={userName} userPic={userPic} userId={userId} />,
            errorElement: <ErrorPage />,
            children: [
                { index: true, element: <PreHome userName={userName} /> },
                { path: 'oppurtunity-list', element: <Home /> },
                { path: 'oppurtunity-detail', element: <OppurtunityDetail /> },
                { path: 'guideline', element: <Guildeline /> }
            ],
        }
    ]);




    return (
        <div>
            <AuthenticatedTemplate>
                <RouterProvider router={router} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginRedirect></LoginRedirect>
            </UnauthenticatedTemplate>
        </div>
    )

}

export default App;

