import { useEffect } from "react";
import OppurtunityDetailSearch from "../components/OppurtunityDetailSearch";
import OppurtunityItem from "../components/OppurtunityItem";

const OppurtunityDetail = () => {
    const title = 'O2E Digital Journey - Opportunity Detail';
    useEffect(() => {
        document.title = title;
      }, [title]);
    return(
        <>
            <OppurtunityItem />
        </>
    )
}

export default OppurtunityDetail;