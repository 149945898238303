import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from './authConfig';
import { callMsGraph,graphData } from "./graph";
import React, { useEffect, useRef, useState } from "react";
import { graphConfig } from "./authConfig";
import { callMsGraphPhoto } from "./graph";

export const LoginRedirect = () => {
    const { instance, account } = useMsal();
    instance.loginRedirect(loginRequest).then((res) => {
        instance.handleRedirectPromise().catch(function (err) {
            console.log('SOMETHING WENT WRONG');
            
        });
    });
 }





