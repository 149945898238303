import React, { useEffect } from 'react';
import { useState } from "react";
const rootUrl = '/';


const GithubContext = React.createContext();

const GithubProvider = ({ children }) => {
    const [rootLink, setRootLink] = useState(rootUrl);
    const [isDesktop, setIsDesktop] = useState(false);
    

    useEffect(() => {
        const media = window.matchMedia('(min-width: 993px)');
        const listener = () => setIsDesktop(media.matches);
        listener();
        window.addEventListener('resize', listener);
    
        return () => window.removeEventListener('resize', listener);
      }, [isDesktop]);

    
    return (
        <GithubContext.Provider value={ {rootLink, isDesktop} }>{children}</GithubContext.Provider>
    );
}

export { GithubProvider, GithubContext };