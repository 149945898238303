import { Fragment, useEffect } from "react";
import Guidelines from "../components/Guidelines";
import Header from "../components/Header";
import LatestOppurtunity from "../components/LatestOppurtunity";
import Search from "../components/Search";
import './Home.css';

const Home = () => {
    const title = 'O2E Digital Journey - Opportunity Lists';
    useEffect(() => {
        document.title = title;
      }, [title]);
    return(
        <>
            <Search />
            <section className="oppurtunity-list-block">
                <LatestOppurtunity />
            </section>
        </>
    )
}

export default Home;