import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <p>Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee ("DTTL"), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as "Deloitte Global") does not provide services to user. Please see www.deloitte.com/about for a more detailed description of DTTL and its member firms.
                    </p>
                    <p>
                    This tool is a software/application-based tool developed for internal use among the users of Deloitte South Asia and exclusively owned by Deloitte Shared Services India LLP (DSSILLP). The tool enables collecting, compiling or obtaining information. User shall not copy, reproduce, modify, distribute, disseminate the tool, nor will the user reverse engineer, decompile, dismantle or obtain access to the underlying formulae of the tool.
                    </p>
                    <p className='copyright'>
                        © 2023 Deloitte Shared Services India LLP
                    </p>
                </div>
            </div>
          </div>
         
         {/* <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>*/}
          

      </footer>

  )
}

export default Footer
